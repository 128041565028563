import React from "react";
import './style.scss';
import { Channels, FAQs, Features, FreeTrialSection, Pricing, Reviews, VmBenefitsOfHiring, VmGetStarted, VmHowItWorks, VmsHomeIntro } from "src/Components";
import { VmsFaqs, faqs, getFAQStructuralData, getImageStructuralData, getPricingPlans } from "src/Constant";
import { OurReviews } from "src/Constant";
import { useDispatch } from "react-redux";
import { toggleCalendlyModal } from "src/Redux/Actions";
import { Helmet } from "react-helmet";
import WhyChooseUs from "src/Components/Home/VmrComponents/WhyChooseUs";

export default function Home() {
    const dispatch = useDispatch()

    const howItWorkList = [
        {
            title: "Schedule a Discovery Call:",
            message: "Click the orange button in the top right corner to schedule a free consultation. During this call, we will learn about your specific needs, including your schedule, documentation style, and EMR system. We'll also be happy to answer any questions you may have."
        },
        {
            title: "Discovery Meeting:",
            message: "We will meet with you over Zoom to discuss your goals and objectives, explain how our program works, and address any additional questions you may have."
        },
        {
            title: "Virtual Scribe Interview:",
            message: "Within seven days, we will screen potential scribe candidates and present you with profiles of the top candidates. We will also arrange a Zoom interview for you to meet and evaluate them."
        },
        {
            title: "14-Day Trial:",
            message: "Begin the onboarding and orientation process with your selected scribe and complete a 14-day trial. If you are not satisfied at the end of the trial period, you owe us nothing. Our success lead will guide you throughout this process to ensure a successful experience."
        }
    ]

    const duties = [
        {
            title: "Real-Time Documentation:",
            options: [
                "Accurately capture and document the details of patient visits through secure audio or video feeds.",
                "Enter patient history, physical exam findings, assessments, and treatment plans directly into the electronic health record (EHR) with precision."
            ]
        },
        {
            title: "Electronic Health Record Management:",
            options: [
                "Maintain and update patient charts in the EHR system, ensuring that all records are thorough and up-to-date.",
                "Organize and manage documentation to support seamless healthcare delivery."
            ]
        },
        {
            title: "Chart Preparation:",
            options: [
                "Prepare comprehensive charts for upcoming patient appointments, including past medical history, medications, and recent lab results."
            ]
        },
        {
            title: "Clinical Documentation:",
            options: [
                "Assist in documenting medical procedures, treatment plans, and follow-up instructions accurately.",
                "Record and manage data from diagnostic tests and imaging studies."
            ]
        },
        {
            title: "Effective Communication:",
            options: [
                "Act as a conduit for communication between healthcare providers and other team members, ensuring critical information is conveyed efficiently.",
                "Support providers by clarifying patient information as required."
            ]
        },
        {
            title: "Support for Coding and Billing:",
            options: [
                "Assist in the preparation of medical coding and billing documentation to facilitate accurate and prompt processing."
            ]
        },
        {
            title: "Confidentiality and Compliance:",
            options: [
                "Adhere to HIPAA regulations and maintain strict patient confidentiality.",
                "Comply with all legal and ethical standards in healthcare documentation."
            ]
        },
        {
            title: "Administrative Assistance:",
            options: [
                "Provide administrative support by scheduling follow-up appointments and organizing patient information.",
                "Develop and refine documentation templates for improved efficiency."
            ]
        },
        {
            title: "Quality Assurance:",
            options: [
                "Conduct quality checks on documentation for accuracy and completeness.",
                "Collaborate with healthcare providers to enhance documentation practices and standards."
            ]
        }
    ]

    const whyChooseList = [
      {
        title: "Live Scribing:",
        message: "Our Virtual Medical Scribes participate in live provider-patient visits through a HIPAA-compliant teleconferencing tool, with access to your practice's EMR. They capture notes according to your preferred documentation standards, saving providers 2-3 hours of documentation time daily."
      },
      {
        title: "Transcription:",
        message: "Providers can record patient visits using audio or video. Our virtual scribes then transcribe these recordings into medical notes, formatted to your specifications."
      },
      {
        title: "Certification & Training:",
        message: "Our scribes are certified professionals who have completed over 450 hours of rigorous training. This extensive preparation ensures they are well-equipped to support your practice effectively."
      },
      {
        title: "Education and Experience:",
        message: "Our scribes are healthcare graduates from the Philippines or Latin America, holding degrees in allied health professions such as Nursing, Pharmacy, and Medicine. They bring a minimum of six months of experience working as medical scribes, providing them with a solid foundation in medical knowledge and terminology."
      },
      {
        title: "Expertise in Popular EMRs:",
        message: "Our scribes are proficient in using a wide range of EMR systems, including Epic, eClinicalWorks, DrChrono, Athenahealth, Practice Fusion, AdvancedMD, AllScripts, TherapyNotes, NextGen, and CareCloud, among others."
      },
      {
        title: "HIPAA Compliance:",
        message: "We prioritize the confidentiality and security of patient information. Our services adhere to all HIPAA regulations, ensuring your practice remains compliant and that patient data is handled with the utmost care."
      },
      {
        title: "Security:",
        message: "We implement robust security measures to protect your data, including encrypted communications, secure access controls, and regular audits to maintain the highest standards of data protection."
      },
      {
        title: "Serving Small and Large Organizations:",
        message: "Whether you are a small practice or a large healthcare organization, our services are tailored to meet your specific needs. We have the experience and flexibility to support practices of all sizes, helping you streamline operations and enhance patient care."
      }
    ]

    const meta_title = "Hire Virtual Medical Scribe | Save 60% on Costs - Get Started"
    const meta_description = "Optimize your practice with HIPAA-compliant Virtual Medical Scribes. Our trained nurses and pharmacists ensure precise, real-time documentation, freeing you for patient care. No more late office hours!"
    const meta_image = "https://honesttaskers.com/vms_image.png"

    return (
        <div className="vms-home">
            <Helmet>
                <meta name="title" content={meta_title} />
                <meta name="description" content={meta_description} />
                <meta property="type" content="website" />
                <meta property="url" content={window.location.href} />
                <meta property="image" content={meta_image} />

                {/* <!-- Google / Search Engine Tags --> */}
                <meta itemprop="name" content={meta_title} />
                <meta itemprop="description" content={meta_description} />
                <meta itemprop="image" content={meta_image} />

                {/* <!-- Facebook Meta Tags --> */}
                <meta property="og:url" content={window.location.href} />
                <meta property="og:type" content="website" />
                <meta property="og:title" content={meta_title} />
                <meta property="og:description" content={meta_description} />
                <meta property="og:image" content={meta_image} />

                {/* <!-- Twitter Meta Tags --> */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={meta_title} />
                <meta name="twitter:description" content={meta_description} />
                <meta name="twitter:image" content={meta_image} />
                <link rel="canonical" href="https://honesttaskers.com/virtual-medical-scribe"></link>
                <script type="application/ld+json">{JSON.stringify(getImageStructuralData(meta_image, meta_title))}</script>
                <script type="application/ld+json">{JSON.stringify(getFAQStructuralData(VmsFaqs))}</script>
            </Helmet>
            <VmsHomeIntro onGetStarted={() => dispatch(toggleCalendlyModal(true))}/>
            <VmBenefitsOfHiring />
            <VmHowItWorks howItWorkList={howItWorkList} title="How It Works: Hiring a Virtual Medical Scribe from Honest Taskers"/>
            {/* <Features /> */}
            <WhyChooseUs title="Why Choose Honest Taskers?" 
                subTitle={"At Honest Taskers, we pride ourselves on:"} 
                whyChoose={whyChooseList} />
            <VmHowItWorks howItWorkList={duties} title={"Duties and Responsibilities:"} />
            <Reviews className="white-bg" Reviews={OurReviews}/>
            <FAQs faqs={VmsFaqs}/>
            <Pricing plans={getPricingPlans('pricing-two')}/>
            <VmGetStarted onGetStarted={() => dispatch(toggleCalendlyModal(true))}/>
            <FreeTrialSection 
                title={"Try Us Risk-Free: 14-Day Trial Offer"}
                message={"Experience the benefits of our Virtual Medical Scribe service with a 14-day trial. Select your preferred scribe and evaluate our services without any financial commitment. If you are not satisfied at the end of the trial period, you owe us nothing. Our success lead will guide you throughout this process to ensure a successful experience, providing support and assistance to meet your specific needs."}/>
            {/* <Channels /> */}
        </div>
    )
}