import React from "react";
import { useDispatch } from "react-redux";
import { Benefits, FAQs, Footer2, GetStarted2, LandingBottom, Pricing, VDAssistantHomeIntro, VmHowItWorks } from "src/Components";
import WhyChooseUs from "src/Components/Home/VmrComponents/WhyChooseUs";
import { getFAQStructuralData, getImageStructuralData, getPricingPlans } from "src/Constant";
import { toggleCalendlyModal } from "src/Redux/Actions";
import { Helmet } from "react-helmet";

export default function VDAssistantHomepage() {
    const dispatch = useDispatch()

    const  benefits = [
        {
            title: "Enhanced Practice Efficiency:",
            message: "By delegating administrative tasks, your team can focus more on patient care and less on paperwork.",
            color: "green"
        },
        {
            title: "Cost-Effective Solution:",
            message: "Save significantly on labor costs compared to hiring full-time in-house staff, reducing your practice’s overheads.",
            color: "green"
        },
        {
            title: "Adaptable Scheduling:",
            message: "Our Virtual Dental Assistants work according to your specific practice hours, providing flexibility and reducing idle time.",
            color: "green"
        },
        {
            title: "Improved Patient Relations:",
            message: "With quick and courteous handling of scheduling and inquiries, patients enjoy better service and shorter wait times.",
            color: "green"
        },
        {
            title: "All-around Administrative Support:",
            message: "From managing patient records to handling insurance processes, our assistants ensure every aspect of your dental office runs smoothly.",
            color: "green"
        }
    ]

    const duties = [
        {
            title: "Appointment Scheduling and Reminders:",
            message: "Efficiently manage appointment books to optimize patient flow and reduce no-shows."
        },
        {
            title: "Patient Communications:",
            message: "Provide friendly and professional responses to patient calls and emails, addressing inquiries and improving engagement."
        },
        {
            title: "Billing and Insurance Processing:",
            message: "Accurate handling of patient billing, insurance claims, and verification, ensuring a smooth financial process."
        },
        {
            title: "Records Management:",
            message: "Maintain up-to-date and organized patient records, ensuring easy access and compliance."
        },
        {
            title: "Dental Supply Management:",
            message: "Keep track of inventory needs and coordinate the ordering of dental supplies, ensuring the clinic is always well-stocked."
        },
        {
            title: "Minimize Missed Calls:",
            message: "Responsible for reducing missed calls by over 95% to enhance patient experience and increase bookings."
        },
        {
            title: "Return Missed Calls Promptly:",
            message: "Ensure 100% of missed calls are returned promptly to provide exceptional customer service."
        },
        {
            title: "Reduce Missed Appointments:",
            message: "Decrease missed appointments by over 50% to improve patient retention and satisfaction."
        },
        {
            title: "Fill Same-Day Openings:",
            message: "Efficiently fill same-day open slots to maintain full schedules for hygienists."
        },
        {
            title: "Increase Hygiene Visits:",
            message: "Boost hygiene visits by over 60% through proactive scheduling and follow-up communication with patients."
        },
        {
            title: "Manage Insurance Verification and Authorizations:",
            message: "Handle insurance verification and prior authorizations efficiently to streamline administrative processes."
        },
        {
            title: "Social Media Management:",
            message: "Oversee social media platforms to enhance brand awareness and promote the practice effectively."
        },
        {
            title: "Optimize Google Business Profile:",
            message: "Optimize the Google Business Profile to improve search rankings and increase client acquisition."
        }
    ]

    const whyChooseList = [
        {
            title: "Professionally Trained:",
            message: "Our Virtual Dental Assistants come with comprehensive training in dental office management and software."
        },
        {
            title: "Dedication and Reliability:",
            message: "We ensure our assistants are not just qualified, but also committed to providing reliable, ongoing support."
        },
        {
            title: "Confidential and Secure:",
            message: "Adhering strictly to HIPAA standards, we guarantee the confidentiality and security of all patient information."
        },
        {
            title: "Tailored to Your Needs:",
            message: "We understand every dental practice is unique, so our services are customized to meet your specific requirements."
        },
        {
            title: "Accessible Support:",
            message: "Beyond daily tasks, our team is always on hand for any additional support or training your practice may need."
        }
    ]

    const faqs = [
        {
            question: "Can Virtual Dental Assistants integrate with our current dental management software?",
            answer: "Absolutely, our assistants are proficient in various dental software platforms and can seamlessly integrate into your current system."
        },
        {
            question: "What happens if the assistant is not the right fit for our practice?",
            answer: "We strive for the perfect match but in case it's not the right fit, we will work with you to find a more suitable assistant."
        },
        {
            question: "How do you ensure the quality of service?",
            answer: "Our assistants undergo regular performance reviews and we maintain open lines of communication for any feedback or adjustments."
        },
        {
            question: "Can Virtual Dental Assistants handle after-hours patient communication?",
            answer: "Yes, our Virtual Dental Assistants can manage patient communications even after regular office hours, ensuring no patient query goes unanswered."
        },
        {
            question: "Are the Virtual Dental Assistants trained in handling dental emergencies over the phone?",
            answer: "Absolutely. They receive training to provide initial guidance in dental emergencies until professional help can be reached."
        },
        {
            question: "How do Virtual Dental Assistants ensure patient data privacy and security?",
            answer: "All our assistants are trained in HIPAA compliance and follow strict protocols to ensure patient data remains secure and confidential."
        },
        {
            question: "Will the same assistant be assigned to our practice consistently?",
            answer: "We strive to provide consistency by assigning the same Virtual Dental Assistant to your practice, fostering a seamless working relationship."
        },
        {
            question: "How are billing and insurance claims managed by Virtual Dental Assistants?",
            answer: "They are adept at managing and processing billing and insurance claims, ensuring accuracy and timely submissions."
        },
        {
            question: "Can Virtual Dental Assistants integrate with my current practice management software?",
            answer: "Yes, they are trained to work with various dental practice management software platforms and can integrate smoothly into your existing system."
        },
        {
            question: "Is there a minimum contract period for hiring a Virtual Dental Assistant?",
            answer: " We offer flexible contract terms with no mandatory long-term commitments, allowing you to choose the duration that best fits your practice's needs."
        },
        {
            question: "In case of technical issues, is support readily available?",
            answer: "Yes, we provide technical support to address any issues swiftly, ensuring uninterrupted service."
        }
    ]

    const meta_title = "Virtual Dental Assistants | Save 60% on Costs - Honest Taskers"
    const meta_description = "Revolutionize your dental practice with Honest Taskers' Virtual Dental Assistants. Enjoy over 60% savings in costs while enhancing operational efficiency and patient care."
    const meta_image = "https://honesttaskers.com/virtual_dental_assistant.png"

    return (
        <div className="vd-assistant-homepage">
            <Helmet>
                <meta name="title" content={meta_title} />
                <meta name="description" content={meta_description} />
                <meta property="type" content="website" />
                <meta property="url" content={window.location.href} />
                <meta property="image" content={meta_image} />

                {/* <!-- Google / Search Engine Tags --> */}
                <meta itemprop="name" content={meta_title} />
                <meta itemprop="description" content={meta_description} />
                <meta itemprop="image" content={meta_image} />

                {/* <!-- Facebook Meta Tags --> */}
                <meta property="og:url" content={window.location.href} />
                <meta property="og:type" content="website" />
                <meta property="og:title" content={meta_title} />
                <meta property="og:description" content={meta_description} />
                <meta property="og:image" content={meta_image} />

                {/* <!-- Twitter Meta Tags --> */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={meta_title} />
                <meta name="twitter:description" content={meta_description} />
                <meta name="twitter:image" content={meta_image} />
                <link rel="canonical" href="https://honesttaskers.com/virtual-dental-assistant"></link>
                <script type="application/ld+json">{JSON.stringify(getImageStructuralData(meta_image, meta_title))}</script>
                <script type="application/ld+json">{JSON.stringify(getFAQStructuralData(faqs))}</script>
            </Helmet>
            <VDAssistantHomeIntro onGetStarted={() => dispatch(toggleCalendlyModal(true))}/>
            <LandingBottom />
            <Benefits subTitle={"of Virtual Dental Assistant"} benefits={benefits}/>
            <VmHowItWorks howItWorkList={duties} title={"Duties and Responsibilities:"}/>
            <WhyChooseUs subTitle={"HonestTaskers' Virtual Dental Assistants?"} whyChoose={whyChooseList}/>
            <FAQs faqs={faqs}/>
            <Pricing hasColor={true} plans={getPricingPlans('no-custom', false, true)} btnText="Contact Us For Pricing"/>
            <GetStarted2 title={"Transform Your Dental Practice with Professional Virtual Assistance!"}
                btnText="Connect with an Expert Assistant Now"
                onGetStarted={() => dispatch(toggleCalendlyModal(true))}/>
            <Footer2 />
        </div>
    )
}