import React from "react";
import './style.scss';
import dateIcon from 'src/Assets/Images/Policy/iconDate.svg';
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

export default function FulfillmentPolicy() {
    const navigate = useNavigate()

    const options = [
        {
            title: "Billing:",
            message: 'Clients are billed based on the actual hours worked by their virtual assistant at the end of each billing period. Charges are calculated according to the hours logged in the virtual assistant\'s timesheet.',
        },
        {
            title: "Cancellation Policy:",
            message: "Clients may cancel their staffing services by providing <strong>two weeks' notice</strong>. This ensures a smooth transition and allows us to fulfill any ongoing tasks or handovers."
        },
        {
            title: "Refund Policy:",
            message: "We offer a full refund if a client can provide sufficient evidence that the virtual assistant did not work the hours documented on their timesheet. All refund requests must include clear documentation and will be reviewed on a case-by-case basis."
        },
        {
            title: "Service Charges:",
            message: "As a service-based business, we only charge clients for completed services. Charges are solely based on the hours worked, as recorded in the virtual assistant's timesheet. Clients will not be charged for any uncompleted or unbilled services."
        }
    ]

    function Item({item, index}) {

        return (
            <div className="terms-item">
                <div className="num-wrapper">{index < 9 ? '0' : ''}{index+1}</div>
                <div className="info">
                    <div className="title">{item.title}</div>
                    <div dangerouslySetInnerHTML={{ __html: item.message }}></div>
                    {
                       item.list && item.list.length > 0 && (
                            <ul>
                                {
                                    item.list.map((lItem, lIndex) => (
                                        <li key={lIndex+5}>{lItem}</li>
                                    ))
                                }
                            </ul>
                        )
                    }
                </div>
            </div>
        )
    }

    return (
        <div className="terms-page">
            <Helmet>
                <link rel="canonical" href="https://www.honesttaskers.com/fulfillment-policy"></link>
            </Helmet>
            <div className="terms-header">
                <div className="container">
                    <div className="bread-crumb">
                        <div className="item" onClick={() => navigate('/')}>Home</div>
                        <div className="seperator">{'>'}</div>
                        <div className="item active">Fulfillment, Cancellation, and Refund Policy</div>
                    </div>
                    <h2>Fulfillment, Cancellation, and Refund Policy</h2>
                    <div className="date">
                        <img src={dateIcon} />
                        <div>10.17.23</div>
                    </div>
                </div>
            </div>
            <div className="terms-content">
                <div className="container">
                    <div className="content">
                        <p className="sub-title">At Honest Taskers, we strive to provide high-quality virtual assistant staffing services tailored to your needs. Our billing and refund policies are designed to be fair and transparent, ensuring your satisfaction.</p>
                        <div className="row">
                            {
                                options.map((item, index) => (
                                    <div className="col-md-6">
                                        <Item item={item} index={index} key={index}/>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}