import React from "react";
import './style.scss';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import OwlCarousel from 'react-owl-carousel';
import Media from "react-media";

export default function VmBenefitsOfHiring() {
    const colors = ["green", "orange", "red"]

    const BenefitsList = [
        {
            title: "Save 2-3 Hours a Day:",
            message: "Doctors can save 2-3 hours daily on patient documentation, allowing more time for patient care and personal activities.",
        },
        {
            title: "Increased Focus on Patient Care",
            message: "With a virtual scribe handling documentation, doctors can spend more time interacting with patients, improving the quality of care and patient satisfaction.",
        },
        {
            title: "Improved Efficiency:",
            message: "Virtual scribes assist with entering patient information into electronic health records (EHRs), reducing the time doctors spend on administrative tasks and increasing overall productivity.",
        },
        {
            title: "Enhanced Documentation Accuracy:",
            message: "Scribes are trained to capture detailed and accurate information, minimizing errors in medical records and ensuring compliance with regulatory standards.",
        },
        {
            title: "Reduced Physician Burnout:",
            message: "By alleviating the burden of extensive documentation, virtual scribes help reduce stress and burnout among physicians, allowing them to maintain a better work-life balance."
        },
        {
            title: "Cost-Effective Solution:",
            message: "Hiring a virtual scribe is often more cost-effective than employing an in-house scribe or administrative assistant, as it eliminates the need for additional office space and resources."
        },
        {
            title: "Real-Time Documentation:",
            message: "Virtual scribes can document patient encounters in real-time during consultations, ensuring that records are up-to-date and reducing the need for after-hours work."
        },
        {
            title: "Increased Appointment Availability:",
            message: "With streamlined documentation processes, doctors can see more patients within the same time frame, increasing practice revenue and improving patient access to care."
        },
        {
            title: "Enhanced Patient Communication:",
            message: "Virtual scribes can assist with follow-up communications and patient instructions, ensuring that patients understand their treatment plans and follow-up procedures."
        },
        {
            title: "Scalability:",
            message: "Practices can easily scale their use of virtual scribes to match patient volume, providing flexibility to adapt to changing demands without the need for long-term commitments."
        },
        {
            title: "Access to Expertise:",
            message: "Virtual scribes often have specialized training and experience, providing high-quality support tailored to the specific needs of the medical practice."
        }
    ]

    function BenefitCard({item, index}) {
        return (
            <div className={`b-card ${colors[index % colors.length]}`}>
                <div className="title-wrapper">
                    <div className="title">{item.title}</div>
                </div>
                <div className="message">{item.message}</div>
                <div className="num-bg"></div>
                <div className="num">{index < 9 ? `0${index+1}` : `${index+1}`}</div>
            </div>
        )
    }

    return (
        <div className="vm-benefits">
            <div className="inner-bg">
                <div className="container">
                    <div className="content">
                        <h2>Benefits of Hiring</h2>
                        <p>HonestTaskers:</p>
                    </div>
                </div>
                <div className="container slider-container">
                    <div className="benefits-slider">
                        <Media
                            queries={{
                                lg: "(min-width: 1655px)",
                                md: "(min-width: 1360px) and (max-width: 1654px)",
                                sm: "(min-width: 1032px) and (max-width: 1359px)",
                                normal: "(max-width: 1031px)",
                            }}
                        >
                            {(matches) => (
                                <OwlCarousel 
                                    className="owl-theme"
                                    stagePadding={
                                        matches.lg ? 0 : matches.md ? 0 : matches.sm ? 0 : 0
                                    }
                                    margin={
                                        matches.lg ? 20 : matches.md ? 20 : matches.sm ? 10 : 0
                                    }
                                    items={matches.lg ? 4 : matches.md ? 3 : matches.sm ? 2 : 1}
                                    dots
                                    loop
                                    autoplay
                                    rewind
                                    autoplaySpeed={500}
                                    autoplayHoverPause
                                    autoplayTimeout={2000}
                                >
                                    {
                                        BenefitsList.map((item, index) => (
                                            <BenefitCard item={item} key={index} index={index}/>
                                        ))
                                    }
                                </OwlCarousel>
                            )}
                        </Media>
                    </div>
                </div>
                {/* <div className="mobile-slider">
                    <div className="container">
                        <div className="row">
                            {
                                BenefitsList.map((item, index) => (
                                    <div className="col-md-6" key={index}>
                                        <BenefitCard item={item} key={index} index={index}/>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div> */}
            </div>
        </div>
    )
}